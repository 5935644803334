import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import LegalNoticePage from '../pagesComponents/legalNoticePage';

const LegalNotice = ({ location }) => (
  <Layout location={location}>
    <LegalNoticePage />
  </Layout>
);

LegalNotice.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default LegalNotice;
